import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col,Form } from 'react-bootstrap';
import { fetchPlayers } from '../../actions/staff/clubPlayerActions';
import { useSelector, useDispatch } from "react-redux";
import { createNewGroup } from "../../actions/staff/createNewGroupActions";
import { useTranslation } from 'react-i18next';
import {  RESET_CREATE_NEW_GROUP_STATE } from '../../constants/staff/playersGroupsConstatnts';
import Button from "../../components/cofButton/Button";
import CustomInput from "../../components/customInput/CustomInput"
const CreatePlayerGroupScreen = ()=>{
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const { players  } = useSelector((state) => state.players );
  const NewGroup = useSelector((state) => state.createNewGroup)
  console.log(NewGroup)
  const { loading,
          error,
          success,
      } = NewGroup
  const [selectedPlayers, setSelectedPlayers] = useState([]); // To store selected players
  useEffect(() => {
    dispatch(fetchPlayers(userInfo.club));
  }, [dispatch, userInfo.club]);
 console.log(players);
   // Function to handle checkbox change
   const handlePlayerCheckboxChange = (playerId) => {
    // Check if the playerId is already in the selectedPlayers array
    if (selectedPlayers.includes(playerId)) {
      // If it is, remove it (uncheck)
      setSelectedPlayers(selectedPlayers.filter(id => id !== playerId));
    } else {
      // If it isn't, add it (check)
      setSelectedPlayers([...selectedPlayers, playerId]);
    }
  };
  const [ groupName, setGroupName] = useState('');
  const handleCreateGroup = (e)=>{
    
    e.preventDefault();
   
      const groupData={
        createdBy:userInfo._id,
        name: groupName,
        members: selectedPlayers,
      }
      
      console.log(groupData)
      dispatch(createNewGroup(userInfo.club, groupData))
  }
  console.log(success)
  useEffect(() => {
    console.log('poziva se useeffect')
    if(success){
      console.log('poziva se useeffect')
     
      navigate(`/staff/clubs/${userInfo.club}/playersgroups`);
      dispatch({ type: RESET_CREATE_NEW_GROUP_STATE })
    }
    else if(error){
      console.log('postoji greska')
    }
       
    }, [dispatch, navigate,success,error])
  return(
   <div>
    <h2 className='text-center my-3'> {t("CreateNewGroup").toUpperCase()}</h2>
    <Row className='text-center justify-content-center'>
     <Col xs={4} lg={2} className="my-auto">
     <h4>{t("GroupName")}:</h4>
     </Col>
     <Col  xs={6} lg={4} className="my-auto">
     <Form.Group controlId="Ime grupe">
            <CustomInput
              type="text"
              placeholder={t("GroupName")}
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
              />
               
          </Form.Group>
     
     </Col>
    </Row>
    <h3 className='text-center my-3'> {t("AddPlayers")}</h3>
    {players.map((player) => (
        <Row key={player._id} className='text-center justify-content-center '>
          <Col xs={8} lg={2} className="">
            <h5>{player.name} {player.surname}</h5>
          </Col>
          <Col xs={1} lg={2} className="text-start ">
            {/* Checkbox for each player */}
            <Form.Check
              type="checkbox"
              id={`player-${player._id}`}
              checked={selectedPlayers.includes(player._id)}
              onChange={() => handlePlayerCheckboxChange(player._id)}
            />
          </Col>
        </Row>
      ))}
<div className="text-center justify-content-center my-3 ">
<Button variant="primary" onClick={handleCreateGroup} className=" w-50  rounded-0 border-0">
            {t("NewGroup")}
          </Button>
</div>

   </div>

  )

}
export default CreatePlayerGroupScreen;