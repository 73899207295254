
import{
    FETCH_PLAYERS_REQUEST,
    FETCH_PLAYERS_SUCCESS,
    FETCH_PLAYERS_FAIL,
    RESET_PLAYERS,

  FETCH_PLAYER_INFO_REQUEST,
  FETCH_PLAYER_INFO_SUCCESS,
  FETCH_PLAYER_INFO_FAIL,

  DELETE_PLAYER_REQUEST,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_FAIL,
  
} from "../../constants/staff/clubPlayerConstants"

import axios from 'axios';

// ... ostale konstante ...

// Lažni podaci za testiranje
const fakePlayersData = [
  {
    _id: 1, // Dodajte playerId
    firstName: 'John',
    lastName: 'Doe',
    position: 'Forward',
    Birthdate: 25,
    dailyStatus: 'Filled',
  },
  {
    _id: 2, // Dodajte playerId
    firstName: 'Jane',
    lastName: 'Smith',
    position: 'Midfielder',
    Birthdate: 28,
    dailyStatus: 'Not Filled',
  },
  // Dodajte više igrača...
];


// Akcija za dohvatanje igrača
export const fetchPlayers = (clubId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_PLAYERS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/staff/clubs/${clubId}/players`, config);

    dispatch({
      type: FETCH_PLAYERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_PLAYERS_FAIL,
      payload: error.message,
    });
  }
};

export const resetPlayers = () => {
  return {
    type: RESET_PLAYERS,
  };
};


export const fetchPlayerInfo = (clubId, playerId) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_PLAYER_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/staff/clubs/${clubId}/players/${playerId}`, config);

    dispatch({
      type: FETCH_PLAYER_INFO_SUCCESS,
      payload: {
        ...data,
      },
    });
  } catch (error) {
    dispatch({
      type: FETCH_PLAYER_INFO_FAIL,
      payload: error.message,
    });
  }
};


export const deletePlayer = (clubId, playerId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_PLAYER_REQUEST });


    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/staff/clubs/${clubId}/players/${playerId}`, config);

    dispatch({ type: DELETE_PLAYER_SUCCESS });

    dispatch(fetchPlayers(clubId));

    
  } catch (error) {
    dispatch({ type: DELETE_PLAYER_FAIL, payload: error.message });
  }
};
