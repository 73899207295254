import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createAdmin } from "../../actions/AdminsAction"; // Promenjen import za akciju
import Button from "../../components/cofButton/Button";
import { useTranslation } from 'react-i18next';
import CustomInput from "../../components/customInput/CustomInput";
import {CREATE_ADMIN_RESET} from "../../constants/AdminsConstants";
const CreateAdminScreen = ({ history }) => {
  const{t} = useTranslation();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminCreate = useSelector((state) => state.adminCreate);
  const { loading, error, success: createsuccess } = adminCreate;

  const handleCreateAdmin = (e) => {
    e.preventDefault();
    dispatch(createAdmin({ name, surname, email, password }));
  };

  
  useEffect(() => {
    if (createsuccess) {
      // Preusmerite se na rutu "/admin/admins"
      navigate("/admin/admins");
      dispatch({type:CREATE_ADMIN_RESET});
    }
  }, [dispatch, createsuccess, navigate]);

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ paddingTop: "100px" }}
    >
      <Row className="mx-2">
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <h2>{t("NewAdmin").toUpperCase()}</h2>
        </Col>

        <Col xs={12}>
          <Form>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput 
              type="text"
              placeholder={t("Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}/>
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
             type="text"
             placeholder={t("Surname")}
             value={surname}
             onChange={(e) => setSurname(e.target.value)} />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput 
             type="email"
             placeholder={t("Email")}
             value={email}
             onChange={(e) => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group style={{ marginBottom: "20px" }}>
            <CustomInput
            
            type="password"
            placeholder={t("Password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            <Button className="w-100 rounded-0 border-0" transparent={false} onClick={handleCreateAdmin}>
              {t("CreateAdmin")}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateAdminScreen;
