import {React, useState,useEffect} from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { fetchAdminById } from "../../actions/AdminsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {  Row, Col } from "react-bootstrap";
const AdminInfoScreen = ()=>{
    const {t} = useTranslation();
    const { adminId } = useParams();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
    const adminDetails = useSelector((state) => state.adminDetails);
    const { loading, error, admin } = adminDetails;
    console.log(admin)
    useEffect(() => {
      if (!admin || !admin.length || admin.length === 0 || !admin[0].user || admin[0].user._id !== adminId) {
        // Dohvatimo informacije o adminu iz Redux store-a
        dispatch(fetchAdminById(adminId));
      } else {
        // Postavimo podatke o adminu u state
        setName(admin[0].user.name);
        setSurname(admin[0].user.surname);
        setEmail(admin[0].user.email);
       
      }
    }, [dispatch, adminId, admin]);
    return(
        <div>

<Container className="text-center mt-5">

      <h2>{t("Admin Info")}</h2>
      
        <div className="my-4">
          <Row className="justify-content-center">
            <Col lg={1} xs={4} className="text-start"><p>{t("Name")}:</p></Col>
            <Col lg={1} xs={4}>{name}</Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={1} xs={4} className="text-start"><p>{t("Surname")}:  </p></Col>
            <Col lg={1} xs={4}>{surname}</Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={1} xs={4} className="text-start"><p>{t("Email")}:  </p></Col>
            <Col lg={1} xs={4}>{email}</Col>
          </Row>
          
        </div>
     
    </Container>




    
        </div>
    )
}

export default AdminInfoScreen;