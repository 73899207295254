// documentActions.js

import axios from 'axios';
import {
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAIL,

  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAIL,

  UPLOAD_STAFFDOCUMENT_REQUEST,
  UPLOAD_STAFFDOCUMENT_SUCCESS,
  UPLOAD_STAFFDOCUMENT_FAIL,

  UPLOAD_PLAYERIMG_REQUEST,
  UPLOAD_PLAYERIMG_SUCCESS,
  UPLOAD_PLAYERIMG_FAIL,
} from '../../src/constants/uploadConstants';

export const uploadDocument = (file, scheduledTaskId) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPLOAD_DOCUMENT_REQUEST });

    // Dohvatanje userInfo iz stanja
    const {
      userLogin: { userInfo },
    } = getState();

    // Konfiguracija zahteva sa dodatnim tokenom za autorizaciju
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const formData = new FormData();
    formData.append('document', file);

    // Zamjena :id parametra sa scheduledTaskId
    const apiUrl = `api/staff/clubs/scheduled-task/${scheduledTaskId}/document/upload`;

    // Slanje zahteva sa konfiguracijom i FormData
    const { data } = await axios.post(apiUrl, formData, config);

    dispatch({ type: UPLOAD_DOCUMENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPLOAD_DOCUMENT_FAIL, payload: error.message });
  }
};

export const getDocument = (scheduledTaskId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_DOCUMENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const apiUrl = `api/staff/clubs/scheduled-task/${scheduledTaskId}/document`;

    const { data } = await axios.get(apiUrl, config);

    dispatch({ type: GET_DOCUMENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_DOCUMENT_FAIL, payload: error.message });
  }
};

export const uploadStaffDocument = (file, userId, callback) => async (dispatch, getState) => {
  try {
    console.log("Upload Staff document")
    dispatch({ type: UPLOAD_STAFFDOCUMENT_REQUEST });

    const formData = new FormData();
    formData.append('document', file);

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    
    console.log(config);

    const { data } = await axios.post(`/api/staff/users/${userId}/personalInfo/document/upload`, formData, config);

    dispatch({
      type: UPLOAD_STAFFDOCUMENT_SUCCESS,
      payload: data,
    });

    if (callback) callback(data);
  } catch (error) {
    dispatch({
      type: UPLOAD_STAFFDOCUMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};


export const uploadPlayerImgAction = (file, userId, callback) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPLOAD_PLAYERIMG_REQUEST });

    const formData = new FormData();
    formData.append('document', file);

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/users/${userId}/personalInfo/document/upload`, formData, config);

    dispatch({
      type: UPLOAD_PLAYERIMG_SUCCESS,
      payload: data,
    });

    if (callback) callback(data);
  } catch (error) {
    dispatch({
      type: UPLOAD_PLAYERIMG_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
