import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../components/cofButton/Button";
import { editTrainer, fetchStaffMember } from "../../actions/staff/clubStaffActions";
import { EDIT_TRAINER_RESET } from '../../constants/staff/clubStaffConstants';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CustomInput from "../../components/customInput/CustomInput"

const EditStaffScreen = () =>{
    const{t}=useTranslation();
    const { staffmemberid } = useParams();
    const { clubid } = useParams();
    console.log(useParams())
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorAlert, setErrorAlert] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [position, setPosition] = useState("");
    const [education, setEducation] = useState("");
    const [email, setEmail]= useState('');
    const [birthdate, setBirthdate] = useState('');
    const trainerDetails = useSelector((state) => state.staffMember);
    const { loading, error, staffMember } = trainerDetails;
    const trainerEdit = useSelector((state) => state.staffMemberEdit);
    const { loading: editLoading, error: editError, success: successUpdate } = trainerEdit;
    console.log(trainerEdit);
     console.log(trainerDetails);
     console.log(staffMember);


//      let initialDate = '';
//       if (staffMember.age) {
//         const ageDate = new Date(staffMember.age);
//         if (!isNaN(ageDate)) {
//           initialDate = ageDate.toISOString().slice(0, 10);
//         }
//       }

// const [age, setAge] = useState(initialDate);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const trainerData = {
          _id: staffmemberid , 
          name,
          surname,
          role: position,
          birthdate,
          education,
          email
        };

        console.log(trainerData)
    
        dispatch(editTrainer(trainerData, staffmemberid, clubid));
      };
      useEffect(() => {
        if (!staffMember || !staffMember._id || staffMember._id !== staffmemberid) {
          // Dohvatimo informacije o adminu iz Redux store-a
          dispatch(fetchStaffMember(clubid, staffmemberid));
        } else {
          setName(staffMember.name);
          setSurname(staffMember.surname);
          setBirthdate(staffMember.birthdate);  // Ispravljeni dio koda
          setPosition(staffMember.position);
          setEducation(staffMember.education);
          setEmail(staffMember.email);
        }
      }, [dispatch, staffMember, staffmemberid]);
    
      useEffect(() => {
        if(successUpdate){
          dispatch({ type: EDIT_TRAINER_RESET })
          dispatch(fetchStaffMember(clubid, staffmemberid));
          navigate(`/club/${clubid}/staff`)
        }
        if(editError)
        { setErrorAlert(true);}
        }, [dispatch, navigate,successUpdate,editError])

        console.log(staffMember);
    return(
        <Container>
        <Row className="justify-content-center" style={{marginTop: "100px"}}>
          <Col xs={12} md={6}>
            <h2  style={{marginBottom: "20px", textAlign:"center"}}>{t("Edit staff member").toUpperCase()}</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label>{t("FirstName")}</Form.Label>
                <CustomInput
                  type="text"
                  placeholder={t("Enter your First Name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="surname">
                <Form.Label>{t("LastName")}</Form.Label>
                <CustomInput
                  type="text"
                  placeholder={t("Enter your Last Name")}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>{t("position")}</Form.Label>
                <Form.Control
                  as="select"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
                   >
                  <option value="">{t("SelectPosition")}</option>
                  <option value="HEAD_COACH">{t("HEAD_COACH")}</option>
                  <option value="FIRST_ASSISTENT">{t("FIRST_ASSISTENT")}</option>
                  <option value="ASSISTENT_COACH">{t("ASSISTENT_COACH")}</option>
                  <option value="ANALIST">{t("ANALIST")}</option>
                  <option value="MAIN_GOALKEEPER_COACH">{t("MAIN_GOALKEEPER_COACH")}</option>
                  <option value="GOALKEEPER_COACH">{t("GOALKEEPER_COACH")}</option>
                  <option value="MAIN_FITNESS_TRAINER">{t("MAIN_FITNESS_TRAINER")}</option>
                  <option value="FITNESS_TRAINER">{t("FITNESS_TRAINER")}</option>
                  <option value="DOCTOR">{t("DOCTOR")}</option>
                  <option value="PHISIO">{t("PHISIO")}</option>
                  <option value="NUTRICIONIST">{t("NUTRICIONIST")}</option>
                  <option value="TEAM_MENAGER">{t("TEAM_MENAGER")}</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="Birthdate">
                <Form.Label>{t("Birthdate")}</Form.Label>
                <CustomInput
                  type="date"
                  placeholder={t("enter your Birthdate")}
                  value={birthdate}
                  onChange={(e) => setBirthdate(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="Education">
                <Form.Label>{t("Education")}</Form.Label>
                <CustomInput
                  type="education"
                  placeholder={t("enter your Education")}
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="Email">
                <Form.Label>{t("Email")}</Form.Label>
                <CustomInput
                  type="email"
                  placeholder={t("enter your Emial")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Button className="w-100 rounded-0 border-0 my-2" transparent={false} type="submit" disabled={editLoading}>
              {t("Edit")}
            </Button>
              {editError && <p className="text-danger mt-3">{editError}</p>}
            </Form>
          </Col>
        </Row>
      </Container>
    )
}
export default EditStaffScreen;